import { useEffect, useState } from 'react'
import { backendRequestAndNotification } from '../../api'
import Pages from '../../all/pages'

const Offers = ({userid, ws}) => {

    const [offers, setOffers] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    useEffect(() => {
        async function getOffers() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/offers/${userid}`, { credentials: 'include' });
                const data = await res.json()
            
                setOffers(data)
            } catch (e) {
                setOffers([])
                return
            }
        }
        
        getOffers()
    }, [realPage, userid])

    useEffect(() => {
        if (ws) {
            ws.on('admin_offers_update', (updatedOffer) => {
                setOffers(prevOffers => prevOffers.map(offer => 
                    offer._id === updatedOffer._id ? updatedOffer : offer
                ))
            })
        }

        return () => {
            if (ws) {
                ws.off('admin_offers_update')
            }
        }
    }, [ws])
    
    const dynamicallyLoadMorePages = async () => {
        let currentTotalPages = Math.round(offers.length / 8)

        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)

            const data = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/offers/${userid}`, { credentials: 'include' })
            setOffers([...offers, ...data])
        }
    }

    return (
        <>
            <div className='users-container'>
                <div className='table-header'>
                    <p>Date</p>
                    <p>Status</p>
                    <p>Wall</p>
                    <p>IP</p>
                    <p>Diamonds</p>
                    <p>Offer ID</p>
                    <p>Offer Name</p>
                    <p>Actions</p>
                </div>

                {Array.isArray(offers) && offers.slice(fakePage * 8, (fakePage + 1) * 8).map((offer, index) => (
                    <div className='table-row' key={offer._id}>
                        <div className='date'>
                            <p>{new Date(offer?.timestamp).toLocaleDateString()}</p>

                            <p className='date-hover'>
                                {new Date(offer?.timestamp).toLocaleTimeString()}
                            </p>
                        </div>
                        <p>{offer?.status}</p>
                        <p>{offer?.wall}</p>
                        <p className='ip clickable' onClick={() => window.open(`https://www.ipqualityscore.com/free-ip-lookup-proxy-vpn-test/lookup/${offer?.user_ip}`, '_blank')}>{offer?.user_ip}</p>
                        <p>{offer?.points}</p>
                        <p>{offer?.offer_id}</p>
                        <p>{offer?.offer_name}</p>
                        <div className='buttons'>
                            {offer?.status === 'completed' && (
                                <button className='hold' onClick={() => {
                                    backendRequestAndNotification('/admin/offers/hold', 'POST', { _id: offer._id })
                                }}>Hold</button>
                            )}
                        </div>
                    </div>
                ))}

                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(offers?.length / 8)} curpage={fakePage} setPage={setFakePage}/>

            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .date {
                    cursor: pointer;
                    position: relative;
                    overflow: visible !important;
                }

                .date-hover {
                    top: 30px;
                    margin: 0px auto;
                    left: 0;
                    right: 0;
                    position: absolute;
                    display: none;

                    width: fit-content;
                    text-align: center;
                }

                .date:hover .date-hover {
                    display: block;
                }

                .buttons {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    flex: 1;
                }

                .hold {
                    border: unset;
                    outline: unset;
                    color: white;
                    border-radius: 3px;
                    cursor: pointer;
                    background-color: #4DAEFF;
                    padding: 5px 10px;
                }

                .hold:hover {
                    opacity: 0.9;
                }

                .clickable {
                    cursor: pointer;
                }
            `}</style>
        </>
    )
}

export default Offers

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import Flag from 'react-world-flags'

import Offers from "./user/offers"
import Withdraws from "./user/withdraws"
import Messages from "./user/messages"
import Notes from "./user/notes"
import Warnings from './user/warnings'

import Slider from '../all/slider'
import Transactions from "./user/transactions"
import Logins from "./user/logins"
import Reversals from "./user/reversals"
import Fingerprints from "./user/fingerprints"

import { backendRequestAndNotification } from '../api/index'

const User = ({ws}) => {

    const [page, setPage] = useState(0)
    const [user, setUser] = useState({message: 'Loading users profile.'})

    const [rank, setRank] = useState(user?.rank ? parseInt(user.rank) : 0)
    const [incBal, setIncBal] = useState(0)
    const [incTokens, setTokens] = useState(0)
    const [max, setMax] = useState(0)
    const [cbRate, setCbRate] = useState('')

    let { userid, subpage } = useParams() 

    const history = useNavigate()
    const redirectToPage = (url) => history(`/admin/users/${userid}/${url}`)

    useEffect(() => {
        async function getUser() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/getbyid/${userid}`, { credentials: 'include' });
                const data = await res.json();
            
                setUser(data.user)
                setMax(data?.user.earn_limit)
                setRank(data?.user.rank)
                setCbRate(data?.cb_rate)

            } catch (e) {
                setUser({message: 'User does not exist.'})
                return
            }
        }
        getUser()
    }, [userid])

    useEffect(() => {
        let pages = ['offers', 'withdraws', 'messages', 'transactions', 'reversals', 'notes', 'logins', 'fingerprints', 'warnings']
        let index = pages.indexOf(subpage.toLowerCase())
        setPage(index)

        if (index === -1) {
            history(`/admin/users`)
        }
    }, [history, userid, subpage])

    useEffect(() => {
        if (ws) {
          ws.on(`admin_user_update`, (data) => {
                if (data?._id === user?._id) {
                    setUser(data)
                }
            })
        }

        return () => {
            if (ws) {
                ws.off('admin_user_update')
            }
        }
    }, [ws, user])
    
    return (
        <>
            <div className='admin-container'>
                <div className='title' onClick={() => history(`/admin/users`)}>
                    <span className='blue'><FontAwesomeIcon icon={faArrowLeft}/></span>
                    <p>Go To Users</p>
                </div>

                <div className='nav'>
                    <p className={'page ' + (page === 0 ? 'active' : '')} onClick={() => redirectToPage(`offers`)}>Offers</p>
                    <p className={'page ' + (page === 1 ? 'active' : '')} onClick={() => redirectToPage('withdraws')}>Withdrawals</p>
                    <p className={'page ' + (page === 2 ? 'active' : '')} onClick={() => redirectToPage('messages')}>Messages</p>
                    <p className={'page ' + (page === 3 ? 'active' : '')} onClick={() => redirectToPage('transactions')}>Transactions</p>
                    <p className={'page ' + (page === 4 ? 'active' : '')} onClick={() => redirectToPage('reversals')}>Reversals</p>
                    <p className={'page ' + (page === 5 ? 'active' : '')} onClick={() => redirectToPage('notes')}>Notes</p>
                    <p className={'page ' + (page === 6 ? 'active' : '')} onClick={() => redirectToPage('logins')}>Logins</p>
                    <p className={'page ' + (page === 7 ? 'active' : '')} onClick={() => redirectToPage('fingerprints')}>Fingerprints</p>
                    <p className={'page ' + (page === 8 ? 'active' : '')} onClick={() => redirectToPage('warnings')}>Warnings</p>
                </div>

                {user?.message ? (
                        <p>{user.message}</p>
                    ) : (
                    <div className='horz'>
                        <div className='user-settings'>
                            <div className='user-top'>
                                <img src={user?.avatar} alt='' className='avatar'/>

                <div className='user-info'>
                <p className='username'>{user?.name}</p>
                <p>{user?._id}</p>
                <p>Original username: {user?.original_name}</p>
                <p>Email: {user?.email}</p>
                <p>Joined {user?.created_at ? new Date(user.created_at).toLocaleDateString() : 'N/A'}</p>
                <p>{user?.balance ? user.balance.toFixed(0) + ' Diamonds' : 'N/A'}</p>
                <p>{user?.adtoken_balance ? user.adtoken_balance.toFixed(0) + ' Ad Tokens' : 'N/A'}</p>
                <p>{user?.earned ? user.earned.toFixed(0) + ' Earned' : 'N/A'}</p>
                <p>{user?.current_ip || 'N/A'}</p>
                <p>{user?.possible_alts ? user.possible_alts.length + ' Possible Alts' : 'N/A'}</p>
                <p>{user?.withdraw_alts ? user.withdraw_alts.length + ' Addy Alts' : 'N/A'}</p>
                <p>Offer ID: <br/>{user?.offer_id}</p>
                <p>Referred by: <br/><a className='ref' href={'https://grindbux.com/admin/users/' + user?.referred_by + '/offers'}>{user?.referred_by}</a></p>
                <p>Affiliate_code: {user?.affiliate_code || 'N/A'}</p>
                <p>Users Referred: {user?.users_referred || 'N/A'}</p>
                <p>Platform ID: {user?.platform_id || 'N/A'}</p>
                <p>Last Country: {user?.country ? <><span>{user.country} <Flag code={user.country} height="14"/></span></> : 'N/A'}</p>
                {/* <p>Real IPs: {user?.realIPs?.[0]?.ip || 'N/A'} / {user?.realIPs?.[1]?.ip || 'N/A'} / {user?.realIPs?.[2]?.ip || 'N/A'}</p> */}
                <p>Chargeback rate: {cbRate}%</p>
                </div>
                            </div>

                            <div className='option'>
                                <p>Change Balance</p>
                                <span>
                                    <input className='number' type='number' value={incBal} onChange={(e) => setIncBal(e.target.value)}/>
                                    <button className='set' onClick={async () => {
                                        if (!user) { return }
                                        
                                        await backendRequestAndNotification('/admin/user/addbal', 'POST', {
                                            userid: user._id,
                                            amount: parseInt(incBal),
                                        })
                                    }}>Add</button>
                                </span>
                            </div>

                            <div className='option'>
                                <p>Change Ad Tokens</p>
                                <span>
                                    <input className='number' type='number' value={incTokens} onChange={(e) => setTokens(e.target.value)}/>
                                    <button className='set' onClick={async () => {
                                        if (!user) { return }
                                        
                                        await backendRequestAndNotification('/admin/user/addtokens', 'POST', {
                                            userid: user._id,
                                            amount: parseInt(incTokens),
                                        })
                                    }}>Add</button>
                                </span>
                            </div>

                            <div className='option'>
                                <p>Earn Limit</p>
                                <span>
                                    <input className='number' type='number' value={max} onChange={(e) => setMax(e.target.value)}/>
                                    <button className='set' onClick={async () => {
                                        if (!user) { return }
                                        
                                        await backendRequestAndNotification('/admin/user/earnlimit', 'POST', {
                                            userid: user._id,
                                            amount: parseInt(max),
                                        })
                                    }}>Set</button>
                                </span>
                            </div>

                            <div className='option'>
                                <p>Rank</p>
                                <span>
                                    <select className='number' value={rank} onChange={(e) => setRank(parseInt(e.target.value))}>
                                        <option value={0}>User</option>
                                        <option value={1}>N/A</option>
                                        <option value={2}>N/A</option>
                                        <option value={3}>Moderator</option>
                                        <option value={4}>Admin</option>
                                        <option value={5}>Owner</option>
                                    </select>
                                    
                                    <button className='set' onClick={async () => {
                                        if (!user) { return }
                                        
                                        await backendRequestAndNotification('/admin/user/rank', 'POST', {
                                            userid: user._id,
                                            rank: rank,
                                        })
                                    }}>Set</button>
                                </span>
                            </div>

                            <div className='option'>
                                <p>Open support</p>

                                <Slider active={user?.is_ticket_open} click={async () => {
                                    if (!user) { return }
                                    
                                    await backendRequestAndNotification('/admin/user/opensupport', 'POST', {
                                        userid: user._id,
                                        value: !user?.is_ticket_open,
                                    })
                                }}/>
                            </div>

                            <div className='option'>
                                <p>Freeze</p>

                                <Slider active={user?.frozen} click={async () => {
                                    if (!user) { return }

                                    await backendRequestAndNotification('/admin/user/freeze', 'POST', {
                                        userid: user._id,
                                        value: !user?.frozen,
                                    })
                                }}/>
                            </div>

                            <div className='option'>
                                <p>Muted</p>

                                <Slider active={user?.muted} click={async () => {
                                    if (!user) { return }
                                    
                                    await backendRequestAndNotification('/admin/user/mute', 'POST', {
                                        userid: user._id,
                                        value: !user?.muted,
                                    })
                                }}/>
                            </div>

                            <div className='option'>
                                <p>Banned</p>

                                <Slider active={user?.banned} click={async () => {
                                    if (!user) { return }
                                    
                                    await backendRequestAndNotification('/admin/user/ban', 'POST', {
                                        userid: user._id,
                                        value: !user?.banned,
                                    })
                                }}/>
                            </div>

                            <div className='option'>
                                <p>Verified</p>

                                <Slider active={user?.verified} click={async () => {
                                    if (!user) { return }
                                    
                                    await backendRequestAndNotification('/admin/user/verify', 'POST', {
                                        userid: user._id,
                                        value: !user?.verified,
                                    })
                                }}/>
                            </div>


                            <div className='option'>
                                <p>Whale.io ban</p>

                                <Slider active={user?.whaleio_ban} click={async () => {
                                    if (!user) { return }
                                    
                                    await backendRequestAndNotification('/admin/user/whaleio-ban', 'POST', {
                                        userid: user._id,
                                        value: !user?.whaleio_ban,
                                    })
                                }}/>
                            </div>
                            
                        </div>

                        {page === 0 && (
                            <Offers userid={userid} ws={ws}/>
                        )}
    
                        {page === 1 && (
                            <Withdraws userid={userid}/>
                        )}
    
                        {page === 2 && (
                            <Messages userid={userid}/>
                        )}
    
                        {page === 3 && (
                            <Transactions userid={userid}/>
                        )}

                        {page === 4 && (
                            <Reversals userid={userid}/>
                        )}
                        
                        {page === 5 && (
                            <Notes userid={userid} ws={ws}/>
                        )}
    
                        {page === 6 && (
                            <Logins userid={userid} ws={ws}/>
                        )}
                        
                        {page === 7 && (
                            <Fingerprints userid={userid} ws={ws}/>
                        )}

                        {page === 8 && (
                           <Warnings user={user} ws={ws}/>
                        )}
                    </div>
                )}
            </div>

            <style jsx>{`
                .admin-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    padding: 50px 200px;
                    gap: 25px;

                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .admin-container::-webkit-scrollbar {
                    display: none;
                }

                .title {
                    font-weight: 500;
                    font-size: 24px;

                    gap: 10px;
                    display: flex;
                    align-items: center;

                    cursor: pointer;
                }

                .title p {
                    margin: unset;
                }

                .page {
                    opacity: 0.5;
                    cursor: pointer;
                }

                .active, .blue {
                    color: #4DAEFF;
                    opacity: 1;
                }

                .nav {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 15px;
                    row-gap: 10px;
                    user-select: none;
                }

                p {
                    margin: unset;
                }

                .horz {
                    display: flex;
                    width: 100%;
                    gap: 15px;
                }

                .user-settings {
                    width: 360px;
                    min-width: 360px;
                    height: fit-content;

                    background: #141A31;
                    border-radius: 12px;

                    padding: 15px;

                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    word-break: break-all;
                }

                .user-top {
                    display: flex;
                    width: 100%;
                    gap: 15px;
                }

                .avatar {
                    width: 125px;
                    height: 125px;
                    border-radius: 8px;
                }
                
                .username {
                    opacity: 1 !important;
                    font-weight: 600;
                    font-size: 1.25rem !important;
                }
                
                .user-info p {
                    opacity: 0.5;
                    margin: unset;
                    font-size: .9rem;
                    margin-top: 5px;
                }

                .user-info p:first-child {
                    margin: unset;
                }

                .option {
                    display: flex;
                    width: 100%;
                    height: 40px;
                    justify-content: space-between;
                    align-items: center;
                    align-items: center;
                }

                .option span {
                    display: flex;
                    height: 100%;
                }

                .calender {
                    background: #1C2344;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    padding: 6px;
                    height: 100%;
                }

                .calender::-webkit-calendar-picker-indicator {
                    filter: invert(1);
                }

                .set {
                    margin-left: 6px;
                    background: #4DAEFF;
                    border-radius: 6px;
                    color: white;
                    border: unset;
                    outline: unset;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 100%;
                }

                .number {
                    background: #1C2344;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    height: 100%;
                    padding: 6px;
                    width: 100px;
                    text-align: center;
                }

                a.ref {
                    color:white;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                p {
                    margin: unset;
                }

                @media only screen and (max-width: 1700px) {
                    .admin-container {
                        padding: 50px 100px;
                    }
                }

                @media only screen and (max-width: 1150px) {
                    .admin-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 1050px) {
                    .admin-container {
                        padding: 50px 25px;
                    }

                    .horz {
                        flex-direction: column;
                    }

                    .user-settings {
                        width: 100%;
                    }
                }

                @media only screen and (max-width: 700px) {
                    .admin-container {
                        padding: 50px 0px;
                    }
                }
            `}</style>
        </>
    )
}

export default User
